<template lang='pug'>
    Profile
</template>

<script>
import Profile from '../components/Profile.vue';
export default {
    runtimeCompiler: true,
    components: {
        Profile,
    },
};
</script>
